<template>
    <div class="ui form">

        <h3 class="ui header" style="color: var(--main-color-red); margin-top: 30px; margin-bottom: 16px; text-align: center">
            * Veuillez remplir les informations suivantes
        </h3>
        <div class="text-blue text-bold" style="margin-bottom: 20px; font-size: 12px !important;">
            <p style="margin-bottom: 5px">
                * Toute fausse information entraînera l’annulation de votre candidarure.
            </p>
            <p style="margin-bottom: 5px">
                * Vérifiez vos informations et choix avant la validation de cette étape.
            </p>
            <p style="margin-bottom: 5px">
                * Les champs marqués d'un <span style="color: red">*</span> sont obligatoires.
            </p>
        </div>


        <div class="two fields">

            <div class="required field inscription-field">
                <label for="num_doss"> Numéro de dossier de préinscription </label>
                <input id="num_doss" type="text" placeholder="Numéro de dossier" v-model.trim="$v.form.num_dossier.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.num_dossier.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="field inscription-field">
                <label for="cne"> CNE </label>
                <input id="cne" type="text" placeholder="CNE" v-model.trim="$v.form.cne.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.cne.$error">
                    <p> <i class="info circle icon"></i> CNE est invalide </p>
                </div>
            </div>

        </div>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="date_naissance"> Date de naissance </label>
                <date-milli id="date_naissance" placeholder="Date de naissance" v-model="$v.form.date_naissance.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.date_naissance.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="tel"> Téléphone </label>
                <input id="tel" type="tel" placeholder="Téléphone" v-model.trim="$v.form.tel.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.tel.$error">
                    <p v-if="!$v.form.tel.required"> <i class="info circle icon"></i> Champ obligatoire. </p>

                    <p v-else> <i class="info circle icon"></i> Téléphone est invalide </p>
                </div>
            </div>

        </div>

        <h4 class="ui dividing header" style="margin-bottom: 25px">Baccalauréat</h4>

        <div class="two fields">
            <div class="required field inscription-field">
                <label for="bac"> Série du baccalauréat </label>
                <input id="bac" type="text" placeholder="Série du baccalauréat" v-model.trim="$v.form.bac.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.bac.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="mention_bac"> Mention du baccalauréat </label>
                <v-select :options="mentionOptions" placeholder="Mention du Baccalauréat" class="v-select-inscription"
                          :reduce="item => item._id" id="mention_bac" v-model="$v.form.mention_bac.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.mention_bac.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="annee_bac"> Année d'obtention du baccalauréat </label>

                <v-select :options="listYears" placeholder="Année du baccalauréat" class="v-select-inscription"
                          id="annee_bac" v-model="$v.form.annee_bac.$model" />


                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.annee_bac.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <h4 class="ui dividing header" style="margin-bottom: 25px">Licence</h4>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="spec_licence"> Spécialité de la licence </label>
                <input id="spec_licence" type="text" placeholder="Spécialité de licence" v-model.trim="$v.form.licence.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.licence.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="date_obtention_lic"> Année d'obtention de la licence </label>
                <v-select :options="listYears" placeholder="Année d'obtention de la licence" class="v-select-inscription"
                          id="date_obtention_lic" v-model="$v.form.annee_licence.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.annee_licence.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="etablissement_licence"> Etablissement de la licence </label>
                <input id="etablissement_licence" type="text" placeholder="Etablissement de la licence"
                       v-model.trim="$v.form.etablisement_licence.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.etablisement_licence.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="moyenne_licence"> Moyenne de la licence <small style="font-size: 13px; color: black; font-weight: 500;">(ex: 10.01)</small></label>
                <input id="moyenne_licence" type="text" placeholder="Moyenne de la licence"
                       v-model.trim="$v.form.moyenne_licence.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.moyenne_licence.$error">
                    <p v-if="!$v.form.moyenne_licence.required"> <i class="info circle icon"></i> Champ obligatoire. </p>

                    <p v-else> <i class="info circle icon"></i> Moyenne est invalide </p>
                </div>
            </div>

        </div>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="mention_licence"> Mention de la licence </label>
                <v-select :options="mentionOptions" placeholder="Mention de la licence" class="v-select-inscription"
                          :reduce="item => item._id" id="mention_licence" v-model="$v.form.mention_licence.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.mention_licence.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <h4 class="ui dividing header" style="margin-bottom: 25px">Master</h4>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="spec_master"> Spécialité du master </label>
                <input id="spec_master" type="text" placeholder="Spécialité du master"
                       v-model.trim="$v.form.master.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.master.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="date_obtention_master"> Année d'obtention du master </label>
                <v-select :options="listYears" placeholder="Année d'obtention du master" class="v-select-inscription"
                          id="date_obtention_master" v-model="$v.form.annee_master.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.annee_master.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="etablissement_master"> Etablissement du master </label>
                <input id="etablissement_master" type="text" placeholder="Etablissement du master"
                       v-model.trim="$v.form.etablisement_master.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.etablisement_master.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="moyenne_master"> Moyenne du master <small style="font-size: 13px; color: black; font-weight: 500;">(ex: 10.01)</small></label>
                <input id="moyenne_master" type="text" placeholder="Moyenne du master"
                       v-model.trim="$v.form.moyenne_master.$model">

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.moyenne_master.$error">
                    <p v-if="!$v.form.moyenne_master.required"> <i class="info circle icon"></i> Champ obligatoire. </p>

                    <p v-else> <i class="info circle icon"></i> Moyenne est invalide </p>
                </div>
            </div>

        </div>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="mention_master"> Mention du master </label>
                <v-select :options="mentionOptions" placeholder="Mention du master" class="v-select-inscription"
                          :reduce="item => item._id" id="mention_master" v-model="$v.form.mention_master.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.mention_master.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <h4 class="ui dividing header" style="margin-bottom: 25px"></h4>

        <div class="two fields">

            <div class="required field inscription-field">
                <label for="labo"> Formation doctorale à choisir </label>
                <v-select :options="listLabo" placeholder="Formation doctorale à choisir" id="labo"
                          class="v-select-inscription arabic-style" v-model="$v.form.labo.$model" @input="getAxes()" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.labo.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

            <div class="required field inscription-field">
                <label for="structure_recherche"> Structure de recherche </label>
                <v-select :options="structure_recherche_list" placeholder="Structure de recherche" id="structure_recherche"
                          class="v-select-inscription" v-model="$v.form.structure_recherche.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.structure_recherche.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>

        </div>

        <div class="two fields">
            <div class="required field inscription-field fifteen wide">
                <label for="axe_recherche"> Axe de recherche </label>
                <multiselect
                        id="axe_recherche"
                        class="v-select-inscription arabic-style"
                        v-model="$v.form.axe_recherche.$model"
                        group-values="items"
                        group-label="title"
                        :allowEmpty = "false"
                        :group-select="false"
                        :multiple="false"
                        placeholder="Axe de recherche"
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        track-by="label"
                        label="label"
                        :searchable="false"
                        :options="axes"
                        :customLabel="item => '- ' + item.label"
                >
                    <template slot="noOptions">La liste est vide.</template>
                </multiselect>

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.axe_recherche.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>
            <div class="required field  inscription-field four wide">
                <label for="salarie"> Salarié </label>
                <v-select :options="[{_id: 'oui', label: 'Oui'}, {_id: 'non', label: 'Non'}]" placeholder="Salarié"
                          :reduce="item => item._id" id="salarie" v-model="$v.form.salarie.$model" class="v-select-inscription" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.form.salarie.$error">
                    <p> <i class="info circle icon"></i> Champ obligatoire. </p>
                </div>
            </div>
        </div>

        <div class="ui five wide column ">
            <div class="flex m-1">
                <button class="mx-auto login-button" @click="save"
                        :disabled="isBusy"
                >
                    Sauvegarder
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import dateMilli from "./generic/dateMilli";
    import {maxLength, maxValue, minLength, minValue, numeric, required} from 'vuelidate/lib/validators'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "formInscripton",
        components: {
            dateMilli, Multiselect
        },
        data() {
            return {
                bacOptions: [
                    {_id: "", label: "ARCHITECTURE"},
                    {_id: "", label: "ARTS APPLIQUÉS"},
                    {_id: "", label: "ARTS CULINAIRES"},
                    {_id: "", label: "ARTS ET INDUSTRIES GRAPHIQUES"},
                    {_id: "", label: "ARTS PLASTIQUES"},
                    {_id: "", label: "BAC LETTRES - OPTION SPORT ETUDE"},
                    {_id: "", label: "BAC SVT - OPTION SPORT ETUDE"},
                    {_id: "", label: "BÂTIMENTS ET TRAVAUX PUBLIQUE"},
                    {_id: "", label: "CHIMIE"},
                    {_id: "", label: "CHIMIE INDUSTRIELLE"},
                    {_id: "", label: "COMMERCE"},
                    {_id: "", label: "COMPTABILITÉ"},
                    {_id: "", label: "CONCEPTION ET BÂTIMENT"},
                    {_id: "", label: "CONDUITE DE L’EXPLOITATION AGRICOLE"},
                    {_id: "", label: "CONSTRUCTION AÉRONAUTIQUE"},
                    {_id: "", label: "CONSTRUCTION MÉCANIQUE"},
                    {_id: "", label: "CONSTRUCTION MÉTALLIQUE"},
                    {_id: "", label: "DESSIN DE BÂTIMENT"},
                    {_id: "", label: "ÉDUCATION PHYSIQUE"},
                    {_id: "", label: "ÉLECTROCHIMIE"},
                    {_id: "", label: "ÉLECTRONIQUE"},
                    {_id: "", label: "ÉLECTROTECHNIQUE"},
                    {_id: "", label: "ÉLECTROTECHNIQUE ET ÉQUIPEMENTS COMMUNICANTS"},
                    {_id: "", label: "ÉNERGIES RENOUVELABLES OPTION : SYSTÈMES ÉNERGIE SOLAIRE"},
                    {_id: "", label: "FABRICATION MÉCANIQUE"},
                    {_id: "", label: "FONDERIE"},
                    {_id: "", label: "FROID ET CLIMATISATION"},
                    {_id: "", label: "FROID ET CONDITIONNEMENT D'AIR"},
                    {_id: "", label: "GÉNIE CHIMIQUE"},
                    {_id: "", label: "GÉNIE CIVIL"},
                    {_id: "", label: "GÉNIE INDUSTRIEL"},
                    {_id: "", label: "GÉNIE MÉCANIQUE"},
                    {_id: "", label: "GROS OEUVRES DU BÂTIMENT"},
                    {_id: "", label: "INDUSTRIE MÉCANIQUE"},
                    {_id: "", label: "INFORMATIQUE"},
                    {_id: "", label: "LANGUE ARABE"},
                    {_id: "", label: "LETTRES"},
                    {_id: "", label: "LETTRES ET SCIENCES HUMAINES"},
                    {_id: "", label: "LETTRES MODERNES"},
                    {_id: "", label: "LETTRES MODERNES ARABISÉES"},
                    {_id: "", label: "LETTRES MODERNES SPÉCIALITÉ ALLEMAND"},
                    {_id: "", label: "LETTRES MODERNES SPÉCIALITÉ ANGLAIS"},
                    {_id: "", label: "LETTRES MODERNES SPÉCIALITÉ ESPAGNOLE"},
                    {_id: "", label: "LETTRES MODERNES SPÉCIALITÉ FRANÇAIS"},
                    {_id: "", label: "LETTRES OPTION ANGLAIS"},
                    {_id: "", label: "LETTRES OPTION ESPAGNOLE"},
                    {_id: "", label: "LETTRES OPTION FRANÇAIS"},
                    {_id: "", label: "LETTRES ORIGINELLES"},
                    {_id: "", label: "LETTRES SPÉCIALITÉ LANGUE"},
                    {_id: "", label: "LOGISTIQUE"},
                    {_id: "", label: "MAINTENANCE DE VÉHICULES AUTO : OPTION VOITURES"},
                    {_id: "", label: "MAINTENANCE INDUSTIELLE"},
                    {_id: "", label: "MAINTENANCE INFORMATIQUE ET RÉSEAUX"},
                    {_id: "", label: "MÉCANIQUE AUTOMOBILE"},
                    {_id: "", label: "MÉCANIQUE INGÉNIERIE"},
                    {_id: "", label: "MICROMÉCANIQUE"},
                    {_id: "", label: "PHYSIQUE CHIMIE"},
                    {_id: "", label: "RÉCEPTION HÔTEL"},
                    {_id: "", label: "SCIENCES AGRONOMIQUES"},
                    {_id: "", label: "SCIENCES CHARIAA"},
                    {_id: "", label: "SCIENCES DE LA GESTION COMPTABLE"},
                    {_id: "", label: "SCIENCES DE LA NATURE"},
                    {_id: "", label: "SCIENCES DE LA VIE ET DE LA TERRE"},
                    {_id: "", label: "SCIENCES DE LA VIE ET DE LA TERRE OPTION ANGLAIS"},
                    {_id: "", label: "SCIENCES DE LA VIE ET DE LA TERRE OPTION ESPAGNOLE"},
                    {_id: "", label: "SCIENCES DE LA VIE ET DE LA TERRE OPTION FRANÇAIS"},
                    {_id: "", label: "SCIENCES ET TECHNOLOGIES ELECTRIQUE"},
                    {_id: "", label: "SCIENCES ET TECHNOLOGIES MÉCANIQUE"},
                    {_id: "", label: "SCIENCES EXPÉRIMENTALES"},
                    {_id: "", label: "SCIENCES EXPÉRIMENTALES ARABISÉES"},
                    {_id: "", label: "SCIENCES EXPÉRIMENTALES BILINGUES"},
                    {_id: "", label: "SCIENCES ÉCONOMIQUES"},
                    {_id: "", label: "SCIENCES ÉCONOMIQUES ET GESTION"},
                    {_id: "", label: "SCIENCES HUMAINES"},
                    {_id: "", label: "SCIENCES HUMAINES OPTION ANGLAIS"},
                    {_id: "", label: "SCIENCES HUMAINES OPTION ESPAGNOLE"},
                    {_id: "", label: "SCIENCES HUMAINES OPTION FRANÇAIS"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES A"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES A OPTION ANGLAIS"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES A OPTION FRANÇAIS"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES B"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES B  OPTION ANGLAIS"},
                    {_id: "", label: "SCIENCES MATHÉMATIQUES B  OPTION FRANÇAIS"},
                    {_id: "", label: "SCIENCES PHYSIQUES"},
                    {_id: "", label: "SCIENCES PHYSIQUES OPTION ANGLAIS"},
                    {_id: "", label: "SCIENCES PHYSIQUES OPTION FRANÇAIS"},
                    {_id: "", label: "SCIENCES TECHNIQUES"},
                    {_id: "", label: "SECRÉTARIAT"},
                    {_id: "", label: "SERVICES DE RESTAURATION"},
                    {_id: "", label: "STYLISME MODÉLISME"},
                    {_id: "", label: "SYSTÈMES ÉLECTRONIQUES NUMÉRIQUES"},
                    {_id: "", label: "TAALIM AL ATIQ"},
                    {_id: "", label: "TECHNIQUE D'ORGANISATION ADMINISTRATIVE"},
                    {_id: "", label: "TECHNIQUE D'ORGANISATION COMPTABLE"},
                    {_id: "", label: "TECHNIQUES COMERCIALES"},
                    {_id: "", label: "TECHNIQUES INDUSTRIELLES"},
                    {_id: "", label: "TECHNIQUES QUANTITATIVES DE GESTION"}
                ],
                mentionOptions: [
                    {_id: "assez_bien", label: "ASSEZ BIEN"},
                    {_id: "bien", label: "BIEN"},
                    {_id: "excellent", label: "EXCELLENT"},
                    {_id: "passable", label: "PASSABLE"},
                    {_id: "tres_bien", label: "TRES BIEN"}
                ],
                form: {
                    num_dossier: null,
                    cne: null,
                    date_naissance: null,
                    tel: null,
                    bac: null,
                    mention_bac: null,
                    annee_bac: null,
                    licence: null,
                    annee_licence: null,
                    etablisement_licence: null,
                    moyenne_licence: null,
                    mention_licence: null,
                    master: null,
                    annee_master: null,
                    etablisement_master: null,
                    moyenne_master: null,
                    mention_master: null,
                    labo: null,
                    structure_recherche: null,
                    axe_recherche: null,
                    salarie: null,
                },
                isBusy: false,
                listYears: [],
                listLabo : [],
                structure_recherche_list : [],
                axes : []
            }
        },
        computed:{
            CURRENT_USER(){
                return this.$store.state.current_user
            },
        },
        methods: {
            generateYears(){
                let currentYear = this.$moment().year(), years = [];
                let startYear = 1960;
                while ( startYear <= currentYear ) {
                    years.unshift(startYear++);
                }
                this.listYears = years
            },
            save() {
                console.log('onSaveClick.');

                if (this.isBusy) return;

                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                this.isBusy = true
                this.form.inscription_completed = true
                this.form._id = this.CURRENT_USER._id

                this.$store.dispatchAsync(this.$SHARED.services.etudiant.update, this.form).then(() => {
                    this.$toast.success(this.$SHARED.messages.etudiant.complete_inscription)
                    let user = JSON.parse(JSON.stringify(this.CURRENT_USER))
                    user.inscription_completed = true
                    user = {...user, ...this.form}
                    this.$store.commit('SET_CURRENT_USER', user)
                })
                    .finally(() => this.isBusy = false)
            },
            getListLabo(data){
                console.log("`getListLabo ...`")
                this.$store.dispatchAsync(this.$SHARED.services.labo.list, {}).then(ar => {

                    this.listLabo = ar
                   // this.getStructure_recherche_list(ar)

                }, code => {
                    this.$toast.error(this.$SHARED.messages[code])
                });
            },
            getStructure_recherche_list(labos){
                console.log("`getStructure_recherche_list() ...`")
                let list=[];
                labos.forEach(e=>{
                    list.push({...{_id: e._id}, ...{label:'Laboratoire de recherche : ' + e.label}})
                })
                this.structure_recherche_list = list
            },
            getAxes(){
                console.log("`getAxes() ...`")
                this.form.axe_recherche = null;
                this.form.structure_recherche = null;
                if(this.form.labo){
                    this.axes = this.form.labo.axes
                    let list;
                    list = this.listLabo.filter(e=>e._id === this.form.labo._id)
                    this.getStructure_recherche_list(list)
                }else{
                    this.axes = []
                    this.structure_recherche_list =[]
                }

            }
        },
        validations(){
            return {
                form: {
                    num_dossier: {required},
                    cne: {maxLength:maxLength(10), minLength: minLength(10)},
                    date_naissance: {required},
                    tel: {required, numeric},
                    bac: {required},
                    mention_bac: {required},
                    annee_bac: {required},
                    licence: {required},
                    annee_licence: {required},
                    etablisement_licence: {required},
                    moyenne_licence: {required, maxValue: maxValue(20), minValue: minValue(0)},
                    mention_licence: {required},
                    master: {required},
                    annee_master: {required},
                    etablisement_master: {required},
                    moyenne_master: {required, maxValue: maxValue(20), minValue: minValue(0)},
                    mention_master: {required},
                    labo: {required},
                    structure_recherche: {required},
                    axe_recherche: {required},
                    salarie: {required},
                }
            }
        },
        mounted() {
            this.generateYears()
            this.getListLabo()
        }
    }
</script>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
    .inscription-field > label {
        font-weight: 300 !important;
        color: var(--main-color-blue) !important;
        font-size: 1.5em !important;
    }

    .inscription-field > input {
        color: var(--main-color-blue);
        padding: 1em 0.5em !important;
        background-color: #fafcfd !important;
        border: 1px solid #0d458472 !important;
        border-radius: 10px !important;
    }

    .inscription-field > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(13, 68, 132, 0.5);
    }

    .login-button {
        padding: 0.8em 1.8em;
        background-color: var(--main-color-green);
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.5em;
        /* text-transform: uppercase; */
        cursor: pointer;
        line-height: 1;
        border: none;
    }
</style>
