<template>
    <div class="ui grid">
        <div class="row centered">
            <div class="eight wide column">
                <h1 class="dossier-welcome text-capitalize">Bonjour {{CURRENT_USER.nom}}</h1>
            </div>
        </div>
        <div class="row centered">
            <div class="eight wide computer ten wide mobile column">
                <h2 class="dossier-section-title">ESPACE DEPÔT DU DOSSIER DE CANDIDATURE</h2>
            </div>
        </div>
        <div class="row centered">
            <div class="twelve wide computer fifteen wide mobile centered column">
                <form-inscripton v-if="!isDossierCreated && !CURRENT_USER.inscription_completed" />
                <div class="dossier-upload-container" v-else>

                    <template v-if="!dossier">
                    </template>
                    <template v-else-if="!isDossierCreated">
                        <div class="dossier-upload-body">

                          <div style="margin-bottom: 30px">
                            <p class="text-blue text-bold">Veuillez téléverser les scans des documents nécessaires pour
                              votre inscription<small> ( * seul le format PDF est accepté )</small></p>
                          </div>


                            <upload-item v-for="(file, key) in staticFiles" :file-name="file.fileName" :message="file.message"
                                         :file-type="file.fileType" :key="key"
                                         :removableFile="true"
                                         @fileRemoved="onFileRemoved"
                                         @uploadCompleted="onUploadCompleted"
                            />

                        </div>
                        <div class="dossier-upload-footer" style="justify-content: end;">
                            <button class="upload-submit" @click="submitAttachments">
                                Soumettre
                            </button>
                        </div>
                    </template>
                    <template v-else-if="isDossierCreated">
                        <div class="submited-title"> Merci !</div>
                        <div class="submited-title">Votre dossier a été Bien téléversé</div>
                        <p class="submited-message">
                            Vous pouvez suivre l’état de traitement
                            <br/> de votre dossier via la page de suivi
                        </p>
                        <div class="flex" style="flex-direction: column">
                            <button class="submited-button mt-2 mx-auto" @click="$router.push({name: 'suivi-demande'})">
                                Suivre l’état de mon dossier
                            </button>

                            <button class="ui right labeled icon large red button mx-auto" :class="{'loading':isBusy}"
                                    style="margin-top: 20px"
                                    @click="downloadRecu"
                                    :disabled="isBusy">
                                <i class="file pdf icon"></i> Télécharger le Reçu
                            </button>

                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import uploadItem from "../components/uploadItem";
    import formInscripton from "../components/formInscripton";

    export default {
        components:{
            uploadItem,
            formInscripton
        },
        data() {
            return {
                isSubmited: false,
                isBusy: false,
                uploadedFiles: [],
                staticFiles: this.$SHARED.consts.staticFiles,
                listLabo : []
            }
        },
        computed: {
            dossier () {
                return this.$store.getters.dossier
            },
            isDossierCreated(){
                return !_.isEmpty(this.dossier)
            },
            CURRENT_USER(){return this.$store.state.current_user},
        },
        methods: {
            submitAttachments(){
                console.log("`submitAttachments`")
                if(this.isBusy) return

                if (this.uploadedFiles.length !== this.staticFiles.length){
                    this.$toast.error("Veuillez télécharger tous les documents de votre candidature")
                    return;
                }

                this.isBusy = true

                const data = {
                    createDossier: true,
                    process : this.$SHARED.process.inscription,
                    attachements: this.uploadedFiles
                }

                this.$store.dispatchAsync(this.$SHARED.services.attachement.create, data).then(ar => {
                    this.$toast.success(this.$SHARED.messages.attachment.upload_succeeded)
                    this.uploadedFiles = []
                    this.$store.commit("SET_DOSSIER", ar.dossier)
                }, code => {
                    this.$toast.error(this.$SHARED.messages[code])

                }).finally(()=>{
                    this.isBusy = false
                })

            },
            onUploadCompleted(data){
                console.log("`onUploadCompleted`")
                this.uploadedFiles.push({...data, depot_dossier: true})
            },
            onFileRemoved(filePath){
                _.remove(this.uploadedFiles, item => item.path === filePath )
            },
            getListLabo(data){
                console.log("`getListLabo ...`")
                this.$store.dispatchAsync(this.$SHARED.services.labo.list, {}).then(ar => {

                    this.listLabo = ar

                }, code => {
                    this.$toast.error(this.$SHARED.messages[code])
                });
            },
            downloadRecu(){
                console.log('downloadRecu');
                this.isBusy=true;
                let query={
                    templateType: "recu",
                    templateOptions: {}
                };

                this.$store.dispatchAsync(this.$SHARED.services.generatePDF, query).then((data) => {
                    let link=this.$store.state.SERVER_ADDRESS + data.link;
                    console.log("link", link);
                    window.open(link);
                    this.isBusy=false;
                }).catch(error=>{
                    this.isBusy= false;
                    this.$toast.error(this.$SHARED.messages[error])
                })
            }
        },
        mounted() {
            this.getListLabo();
           // this.$store.state.current_process = 'inscription';
           // this.$store.dispatch("get_steps");
        }
    }
</script>

<style scoped>
    .dossier-welcome {
        font-weight: 300;
        font-size: 4em;
        color: var(--main-color-blue);
        text-align: center;
        line-height: 1;
        padding-bottom: 0.35em;
        border-bottom: 2px solid var(--main-color-blue);
    }

    .dossier-section-title {
        color: var(--main-color-blue);
        text-align: center;
        font-weight: 800;
        font-size: 2.25em;
    }

    .dossier-upload-container {
        background-color: white;
        padding: 2em;

        box-shadow: 0px 0px 33px -8px rgba(0, 0, 0, 0.25);
        border-radius: 9px;
    }

    .dossier-upload-body {
        /* min-height: 300px; */
    }

    .dossier-upload-footer {
        margin-top: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .file-upload label {
        border-radius: 5px;
        color: #fff;
        background-color: var(--main-color-blue);
        display: inline-block;
        padding: 0.5em 1em;
        cursor: pointer;
    }

    .file-upload input[type=file] {
        display: none;
    }

    .upload-submit {
        border: none;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        background-color: var(--main-color-green);
        padding: 0.75em 1em;
        cursor: pointer;
    }

    .submited-title {
        font-weight: 700;
        font-size: 2.25em;
        text-transform: uppercase;
        line-height: 1.5;
        text-align: center;
        color: var(--main-color-orange);
    }

    .submited-message {
        font-weight: 400;
        font-size: 2.125em;
        /* text-transform: uppercase; */
        line-height: 1.5;
        text-align: center;
        color: var(--main-color-blue);
    }

    .submited-button {
        border: none;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        background-color: var(--main-color-blue);
        padding: 0.75em 1em;
        cursor: pointer;
    }
    @media (max-width:700px){
        .dossier-section-title{
            font-size: 2em;
        }
        .dossier-welcome{
            font-size: 2.3em;
        }
    }
    /* @media all and (max-width:700px){
		.dossier-section-title{
            font-size: 1.5em;
        }
    } */
</style>
